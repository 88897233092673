import React from "react";

const Getid = ({handleClaimNowClick}) => {


  return (
    <div className="fixed z-50 bottom-0 bg-yellow-500 w-full h-auto p-3 flex justify-center">
    <button
      onClick={handleClaimNowClick}
      className="px-28 py-2 grid place-items-center bg-gradient-to-r from-black to-[#102076] text-white rounded-lg text-xl shadow-md hover:bg-blue-700 transition-all duration-300"
    >
      Get your !D now
    </button>
  </div>
  
  );
};

export default Getid;
